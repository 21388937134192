<template>
  <div class="promoter-order" ref="container">
    <NavBar left-arrow title="佣金订单" @click-left="goBack" fixed></NavBar>
    <List v-model="loading" :finished="finished" finished-text="没有更多了" @load="getList">
      <div class="list-item" v-for="(item, index) in list" :key="index">
        <div class="list-item-con">
          <div class="list-item-left">
            <div class="auth-img">
              <img :src="item.order.headimgurl ? item.order.headimgurl:auth" alt />
            </div>
            <div class="auth-info">
              <span>{{item.order.mobile}}</span>
              <span v-if="item.order.amount">实付金额: {{item.order.amount}} 元</span>
            </div>
          </div>
          <div class="list-item-left" style="margin-right:10px">
            <div class="auth-info—2">
              <span>状态: {{ item.status == 0 ? '未结算':'已结算' }}</span>
              <span>来源: {{item.style | type }}</span>
            </div>
          </div>
        </div>
        <div class="bot-text" v-if="item.order.mobile">
          <span>新人号码: {{ item.order.mobile }}</span>
        </div>
        <div class="bot-text" v-if="item.order.create_time">
          <span>邀请时间: {{ item.order.create_time }}</span>
        </div>
        <div class="bot-text" v-if="item.order.name">
          <span>类型: {{ item.order.name }}</span>
        </div>
        <div class="bot-text" v-if="item.order.ordersn">
          <span>订单编号: {{ item.order.ordersn }}</span>
        </div>
        <div class="bot-text" v-if="item.order.payment_time">
          <span>支付时间: {{ item.order.payment_time | filterTime }}</span>
        </div>
      </div>
    </List>
  </div>
</template>
<script>
import User from "@/api/user";
import { NavBar, List } from "vant";
export default {
  name: "PromoterOrder",
  components: {
    NavBar,
    List
  },
  props: {},
  filters: {
    // 1订单 2充值 3提现 4邀请新人 5其他
    type: function(val) {
      switch (val) {
        case 1:
          return "订单";
        case 2:
          return "充值 ";
        case 3:
          return "提现 ";
        case 4:
          return "邀请新人";
        case 5:
          return "其他";
        default:
          break;
      }
    }
  },
  data: function() {
    return {
      list: [],
      page: 0,
      finished: false,
      loading: true,
      auth: require("../../../../../../public/static/images/vip/touxiang_1.png")
    };
  },
  mounted: function() {
    this.getList();
  },
  methods: {
    async getList() {
      try {
        const res = await User.getCommissionLogs(++this.page);
        if (res.data.total > 0) {
          for (let i in res.data.data) {
            this.list.push(res.data.data[i]);
          }
          this.loading = false;
          if (res.data.total == this.list.length) this.finished = true;
        } else {
          this.finished = true;
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style scoped>
.promoter-order {
  overflow-x: hidden;
  padding-top: 40px;
}
.list-item {
  width: 96%;
  height: auto;
  border-radius: 6px;
  margin: 10px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.list-item-left {
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
}
.auth-img {
  width: 60px;
  height: 60px;
  overflow: hidden;
  margin-left: 10px;
  border-radius: 50%;
  background-color: bisque;
}
.auth-img img {
  width: 100%;
  height: 100%;
}
.list-item-con {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #fdfdfd;
}
.auth-info,
.auth-info—2 {
  flex: 1;
  height: 100%;
  display: flex;
  padding: 20px 0;
  margin-left: 10px;
  font-size: 14px;
  justify-content: space-between;
  flex-direction: column;
}

.auth-info span:nth-child(1) {
  font-size: 16px;
  color: #363636;
}
.auth-info span:nth-child(2) {
  font-size: 12px;
  color: #696969;
}
.item-right {
  flex: 1;
  padding: 20px 0;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.bot-text {
  width: 100%;
  height: 30px;
  padding-left: 20px;
  line-height: 30px;
}
</style>